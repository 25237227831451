import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

(function (window) {
  // 'use strict';

  var thisScript = document.currentScript;

  function initTracker() {
    var trackerObj = {
      snowplowEnabled: false, //flag to denote if snowplow tracker was enabled for this instance
      tdEnabled: false, //flag to denote if td tracker was enabled for this instance
      collectorHost: "", //snowplow collector host
      appId: "", //snowplow app id
      td: null, //td instance
      lob: "", //line of business for td tracking
      domain: "", //domain for td tracking

      /*** **************************************************************************/
      /*** ************ Public methods - exposed for the container ************ *****/
      /*** **************************************************************************/

      initConfig(obj) {
        if (obj.config && obj.config.snowplow) {
          this.snowplowEnabled = true;
          const aid = obj.config.snowplow.appId;
          const chost = obj.config.snowplow.cDomain;
          var cname = chost;
          if (chost) {
            if (chost === 'com-informa-prod1.mini.snplow.net') {
              cname = window.location.hostname;
            }
            else {
              cname = chost.substring(2, chost.length);
            }
            fetchCDWhiteList(cname);
          }

          if (aid && chost) {
            this.initSnowplowConfig(obj.config.snowplow);
          } else {
            console.err("appId and cDomain are required.");
          }
        }

        if (obj.config && obj.config.td) {
          this.tdEnabled = true;
          const lob = obj.config.td.lob;
          const domain = this.getTDDomain(obj.config.td.domain);
          if (lob && domain) {
            this.initTDConfig(lob, domain);
          } else {
            console.err("lob and domain are required.");
          }
        }

        let lastUrl = location.href;
        if (obj.config && obj.config.snowplow) {
          if (!obj.config.snowplow.contentTagEnabled) {
            new MutationObserver(() => {
              const url = location.href;
              if (url !== lastUrl) {
                console.log('pv evt occurred.');
                lastUrl = url;
                window.snowplow("trackPageView");
              }
            }).observe(document, { subtree: true, childList: true });
          }
        }
        //Lytics Tag
        if (obj.config && obj.config.lytics) {
          // this.initLyticsConfig();
        }


        /*Bombora Visitor insights tag*/
        // if (obj.config && obj.config.vi) {
        //   this.setUpVICookies()
        //   this.initVIConfig();
        // }

        //Capture eloqua hash email from GET parameters
        this.setEloquaHashEmail();
      },

      getTDDomain(domain) {
        try {
          if (domain && domain.indexOf('.') > -1) {
            return domain.substring(0, domain.indexOf('.'));
          } else {
            return domain;
          }
        } catch (e) {
          return domain;
        }
      },

      setTrackingUID(userId) {
        if (this.snowplowEnabled) {
          if (userId) {
            /* document.cookie = "IRIS_ID=" + userId + "; path=/;"; */
            this.setDomainCookie('IRIS_ID', userId);
          }
          window.snowplow("setUserIdFromCookie", "IRIS_ID");
        }

        if (this.tdEnabled) {
          this.setTDCustomData(userId);
        }
      },

      clearTrackingUID() {
        try {
          if (this.snowplowEnabled) {
            let collectorName = this.collectorHost;
            let domainName = collectorName ? collectorName.substring(1, collectorName.length) : collectorName;
            if (collectorName && collectorName.startsWith('c.') && (window.location.hostname.indexOf(domainName) > 0)) {
              document.cookie = "IRIS_ID=; Max-Age=-99999999; path=/; domain=" + domainName + ";";
            }
            else {
              document.cookie = "IRIS_ID=; Max-Age=-99999999; path=/;";
            }
            window.snowplow("clearUserData", { preserveSession: true, preserveUser: true });
          }
        } catch (rrr) {
          console.log('Could not clear the cookie');
        }
        //clear eloqua email
        /* document.cookie = "_iris_eloqua_em=; Max-Age=-99999999; path=/;";*/
      },

      trackImpression(data) {
        if (this.snowplowEnabled) {
          window.snowplow("trackSelfDescribingEvent", {
            event: {
              schema:
                "iglu:com.snowplowanalytics.snowplow/ad_impression/jsonschema/1-0-0",
              data: data,
            },
          });
        }
      },

      trackUriRedirect(data) {
        if (this.snowplowEnabled) {
          window.snowplow("trackSelfDescribingEvent", {
            event: {
              schema:
                "iglu:com.snowplowanalytics.snowplow/uri_redirect/jsonschema/1-0-0",
              data: {
                uri: data.url
              },
            },
          });
        }
      },

      trackStructEvt(data) {
        if (this.snowplowEnabled) {
          if (data) {
            window.snowplow("trackStructEvent", {
              category: data.cat,
              action: data.act,
              label: data.lbl,
              property: data.prop,
              value: data.val,
            });
          }
        }
      },

      setDomainCookie(name, val) {
        try {
          let collectorName = this.collectorHost;
          let domainName = collectorName ? collectorName.substring(1, collectorName.length) : collectorName;
          if (collectorName && collectorName.startsWith('c.') && (window.location.hostname.indexOf(domainName) > 0)) {
            document.cookie = name + "=" + val + "; path=/; domain=" + domainName + ";";
          }
          else {
            document.cookie = name + "=" + val + "; path=/;";
          }
        } catch (err) {
          console.log('Domain cookie is not set');
        }
      },

      trackPageView(data) {
        if (this.snowplowEnabled) {
          if (data && data.title) {
            window.snowplow('trackPageView', data.title);
          }
          else {
            window.snowplow('trackPageView');
          }
        }
      },

      trackUnloadEvent(cfg) {
        try {
          if (cfg.contentTagEnabled && cfg.context) {
            window.snowplow("trackSelfDescribingEvent", {
              event: {
                schema: "iglu:com.iiris/page_unload_event/jsonschema/1-0-4",
                data: this.getUnloadEvtData()
              },
              context: this.getContext(cfg.context)
            });
          }
          else {
            window.snowplow("trackSelfDescribingEvent", {
              event: {
                schema: "iglu:com.iiris/page_unload_event/jsonschema/1-0-4",
                data: this.getUnloadEvtData()
              }
            });
          }
        } catch (error) {
          console.log("Error occurred during page_unload event");
        }
      },

      getUnloadEvtData() {
        return {
          minXOffset: Math.max(0, Math.round(aggregatedEvent.minXOffset)),
          maxXOffset: Math.max(0, Math.round(aggregatedEvent.maxXOffset)) <= 600000 ?
            Math.max(0, Math.round(aggregatedEvent.maxXOffset)) : 600000,
          minYOffset: Math.max(0, Math.round(aggregatedEvent.minYOffset)),
          maxYOffset: Math.max(0, Math.round(aggregatedEvent.maxYOffset)) <= 600000
            ? Math.max(0, Math.round(aggregatedEvent.maxYOffset)) : 600000,
          activeSeconds: (aggregatedEvent.numEvents * 10) <= 604800 ? (aggregatedEvent.numEvents * 10) : 604800
        };
      },

      handleCrossDomainLink(evt) {
        let rawUrl = evt.detail.url;
        let tgtUrl = new URL(evt.detail.url);
        let tgtHost = tgtUrl.hostname;
        let tgtPath = tgtUrl.pathname;
        let tgtSearch = tgtUrl.search;
        let duidCookie = getSnowplowDuid();
        const crossDomainDests = getCrossDomainList();
        if (allowRestrictedLinking(tgtUrl, crossDomainDests)) {
          if (!isSameDomainLink(rawUrl)) {
            if (!qStrContainsSpParam(rawUrl) && isQueryStringValid(tgtSearch)) {
              if (duidCookie) {
                if (!tgtSearch.includes('_sp')) {
                  if (tgtSearch.includes('?')) {
                    tgtSearch = tgtSearch + '&_sp=' + duidCookie;
                  }
                  else {
                    tgtSearch = tgtSearch + '?_sp=' + duidCookie;
                  }
                }
              }
            }
          }
        }

        let tgt = '_blank';
        if (evt.detail.mode && evt.detail.mode == 'same') {
          tgt = '_self';
        }

        window.open('https://' + tgtHost + tgtPath + tgtSearch, tgt);
      },

      getUserId(userId) {
        try {
          if (userId && userId.trim() !== "") {
            return userId
          }
          else {
            return getCookie('IRIS_ID');
          }
        } catch (e) {
          console.log(e);
          return userId;
        }
      },

      getDUId() {
        let iris_cookies = document.cookie.split(';').reduce((cookies, cookie) => {
          const [name, value] = cookie.split('=').map(c => c.trim());
          cookies[name] = value;
          return cookies;
        }, {});

        let props = Object.getOwnPropertyNames(iris_cookies);

        let sp_cookie = '';

        for (var i = 0; i < props.length; i++) {
          if (props[i].startsWith('_sp_id.')) {
            sp_cookie = props[i];
            break;
          }
        }

        if (iris_cookies[sp_cookie]) {
          return iris_cookies[sp_cookie].split('.')[0];
        }
        else
          return null;
      },

      getUHash() {
        const params = new Proxy(
          new URLSearchParams(window.location.search),
          { get: (searchParams, prop) => searchParams.get(prop) }
        );

        let eUserHash = params.sp_eh ? params.sp_eh : getCookie('sp_eh'); 
        let userHash = getCookie('IRIS_UHASH');

        // code changes for Scenario 2: 
        if(userHash && userHash.trim() !== ""){
          return userHash;
        }
        // code changes for Scenario 1:
        if (eUserHash && eUserHash.trim() != "") {
          this.setUHash(eUserHash,'eUser')
          return eUserHash;
        }
        return null;
      },

      setUHash(hash,type) {
        if (type === 'eUser') {
           document.cookie = "sp_eh=" + hash;
        }
        document.cookie = "IRIS_UHASH=" + hash + "; path=/; samesite=strict; secure;domain=" + window.location.hostname;
      },

      trackBusinessEvt(data, context) {
        if (this.snowplowEnabled) {
          if (data) {
            window.snowplow("trackSelfDescribingEvent", {
              event: {
                schema: 'iglu:com.iiris/business_event/jsonschema/1-0-1',
                data: {
                  timestamp: data.ts,
                  cookieId: this.getDUId() ? this.getDUId() : '',
                  userId: this.getUserId(data.userId),
                  sessionId: data.sessionId,
                  pageURL: window.location.href,
                  entityId: data.entityId,
                  type: data.type,
                  transactionId: data.transactionId,
                  formId: data.formId,
                  status: data.status
                }
              },
              context: this.getAssetContext(context)
            });
          }
        }
      },

      handleHrefCDLink(linkElement) {
        const crossDomainDests = getCrossDomainList();
        let cdUrl = new URL(linkElement.href);
        let cdUrlSearch = cdUrl.search;
        let rawUrl = linkElement.href;
        let tgtHost = cdUrl.hostname;
        let tgtPath = cdUrl.pathname;
        /* NA href values for crossdomain linking */
        const hrefVals = ['javasript:', 'mailto:', 'tel:'];

        if (allowRestrictedLinking(linkElement, crossDomainDests)) {
          /* 1. Check if the target url is not from the same domain */
          if (!isSameDomainLink(linkElement.href)) {
            /* 2. Check if href value is url only */
            if ((linkElement.href.includes('javascript:')) ||
              (linkElement.href.includes('tel:')) ||
              (linkElement.href.includes('mailto:'))) {
              return false;
            }
            /* 3. Check if the URL has a valid query string */
            /* 4. Check if the query string has _sp param */
            else if (!isQueryStringValid(cdUrlSearch) || qStrContainsSpParam(cdUrl.href)) {
              return false;
            }
            else {
              if (!(linkElement.href.indexOf('#') > 0)) {
                let duidCookie = getSnowplowDuid();
                if (duidCookie) {
                    if (linkElement.search.includes('?')) {
                      linkElement.search = linkElement.search + '&_sp=' + duidCookie;
                    }
                    else {
                      linkElement.search = linkElement.search + '?_sp=' + duidCookie;
                    }
                  linkElement.href = 'https://' + tgtHost + tgtPath + linkElement.search;
                  return true;
                }
                else {
                  return false;
                }
              }
              else {
                return false;
              }
            }
          }
        }
      },

      trackPartnerInteractionEvt(data) {
        if (this.snowplowEnabled) {
          const uuid = uuidv4();
          const hash = CryptoJS.MD5(uuid);
          data.interaction_uid = hash.toString(CryptoJS.enc.Hex).toUpperCase();
          data.interaction_date = new Date().toISOString().replace('T', ' ').replace('Z', '')
          window.snowplow("trackSelfDescribingEvent", {
            event: {
              schema:
                "iglu:com.iiris/pie/jsonschema/1-0-1",
              data: data,
            },
          });
        }
      },

      /*** **************************************************************************/
      /*** ************ Private methods - Snowplow tracker ******* ************ *****/
      /*** **************************************************************************/

      initSnowplowConfig(cfg) {
        /* NA href values for crossdomain linking */
        const hrefVals = ['javasript:', 'mailto:', 'tel:'];
        includeSnowplowScripts();
        const appId = cfg.appId;
        const collectorHost = cfg.cDomain;
        const context = cfg.context;
        var cfgCount = (window.IIRISTracker) ? window.IIRISTracker.instCount : 0;
        window.snowplow("newTracker", "sp1", collectorHost, {
          appId: appId,
          eventMethod: "beacon",
          discoverRootDomain: true,
          cookieSameSite: "Lax",
          postPath: this.getPostPath(collectorHost),
          crossDomainLinker: this.handleHrefCDLink,
          contexts: {
            performanceTiming: false,
          },
        });

        window.snowplow("setUserIdFromCookie", "IRIS_ID");

        snowplow('enableActivityTracking', {
          minimumVisitLength: 30,
          heartbeatDelay: 30
        });
        window.snowplow("enableActivityTrackingCallback", {
          minimumVisitLength: 30,
          heartbeatDelay: 30,
          callback: function (event) {
            aggregatedEvent = {
              minXOffset: aggregatedEvent.minXOffset < event.minXOffset ? aggregatedEvent.minXOffset : event.minXOffset,
              maxXOffset: aggregatedEvent.maxXOffset > event.maxXOffset ? aggregatedEvent.maxXOffset : event.maxXOffset,
              minYOffset: aggregatedEvent.minYOffset < event.minYOffset ? aggregatedEvent.minYOffset : event.minYOffset,
              maxYOffset: aggregatedEvent.maxYOffset > event.maxYOffset ? aggregatedEvent.maxYOffset : event.maxYOffset,
              numEvents: aggregatedEvent.numEvents + 1
            };
          }
        });


        if (window.IIRISTracker && window.IIRISTracker.instCount > 0) {
          window.snowplow('crossDomainLinker', this.handleHrefCDLink);
        }

        /**
         * Event listeners
         */
        var me = this;
        document.addEventListener("visibilitychange", function () {
          if (window.IIRISTracker && cfgCount == window.IIRISTracker.instCount) {
            if (document.visibilityState == "hidden") {
              me.trackUnloadEvent(cfg);
            }
          }
        });

        window.addEventListener('recommendCTREvent', function (evt) {
          if (window.IIRISTracker && cfgCount == window.IIRISTracker.instCount) {
            let data = {}
            data.url = evt.detail.target_url
            me.trackUriRedirect(data)
          }
        });

        window.addEventListener("popstate", function () {
          if (window.IIRISTracker && cfgCount == window.IIRISTracker.instCount) {
            me.trackUnloadEvent(cfg);
          }
        });

        window.addEventListener("crossDomainClick", function (evt) {
          if (window.IIRISTracker && cfgCount == window.IIRISTracker.instCount) {
            me.handleCrossDomainLink(evt);
          }
        });

        window.snowplow("enableLinkClickTracking");

        // Snowplow Pageview with Custom Context
        if (cfg.contentTagEnabled && context) {
          window.snowplow(
            'trackPageView',
            {
              context: this.getContext(context)
            });
        }
        else {
          window.snowplow("trackPageView");
        }

        this.collectorHost = collectorHost;
        this.appId = appId;

        // Separated duid as a cookie
        var spduid = getSnowplowDuid();
        if (spduid) {
          /* document.cookie = "_iris_duid=" + spduid + "; path=/;";*/
          this.setDomainCookie('_iris_duid', spduid);
        }
      },

      getPageIsSponsored(context) {
        var pageIsSpons = context['pageIsSponsored'];
        var boolPgRes = false;
        if (typeof (pageIsSpons) == 'string') {
          boolPgRes = pageIsSpons.toLowerCase() == 'true';
        }
        else {
          boolPgRes = pageIsSpons
        }
        return boolPgRes;
      },

      getPageDesc(desc) {
        if (desc) {
          return desc;
        }
        else {
          return (document.querySelector('meta[name="description"]') ? document.querySelector('meta[name="description"]').content : '');
        }

      },

      getSecondaryTerms(secTerms) {
        if (secTerms) {
          if (Array.isArray(secTerms))
            return secTerms.join(',');
          else
            return secTerms;
        }
        return secTerms;
      },


      getContext(context) {
        return [{
          schema: "iglu:com.iiris/content_taxonomy_ctx/jsonschema/1-0-15",
          data: {
            "byline": context['byline'],
            "canonicalUrl": context['canonicalUrl'],
            "contentAuthor": context['contentAuthor'],
            "contentLabel": context['contentLabel'],
            "contentSource": context['contentSource'],
            "gatingLevel": context['gatingLevel'],
            "contentPubDate": context['contentPubDate'],
            "pageID": context['pageID'],
            "pageDesc": this.getPageDesc(context['pageDesc']),
            "pageIsSponsored": this.getPageIsSponsored(context),
            "pageSponsor": context['pageSponsor'],
            "pageTitle": context['pageTitle'],
            "pageType": context['pageType'],
            "primaryTerm": context['primaryTerm'],
            "auxiliaryTerm": this.getSecondaryTerms(context['secondaryTerm']),
            "tags":context['tags']
          }
        }];
      },

      getAssetContext(context) {
        return [{
          schema: "iglu:com.iiris/asset/jsonschema/2-0-1",
          data: {
            "id": context['id'],
            "name": context['name'],
            "type": context['type'],
            "created": context['created'],

            "lastUpdated": context['lastUpdated'],
            "url": context['url'],
            "tags": context['tags'],
            "metadata": context['metadata'],
          }
        }];
      },

      getPostPath(collectorHost) {
        if (collectorHost === 'com-informa-prod1.mini.snplow.net') {
          return '/com.snowplowanalytics.snowplow/tp2';
        }
        else
          return '/com.iiris/ed0';
      },

      /*** **************************************************************************/
      /*** *********************   Private methods - Lytics tracker ******* *********/
      /*** **************************************************************************/
      initLyticsConfig() {
        !function () { "use strict"; var o = window.jstag || (window.jstag = {}), r = []; function n(e) { o[e] = function () { for (var n = arguments.length, t = new Array(n), i = 0; i < n; i++)t[i] = arguments[i]; r.push([e, t]) } } n("send"), n("mock"), n("identify"), n("pageView"), n("unblock"), n("getid"), n("setid"), n("loadEntity"), n("getEntity"), n("on"), n("once"), n("call"), o.loadScript = function (n, t, i) { var e = document.createElement("script"); e.async = !0, e.src = n, e.onload = t, e.onerror = i; var o = document.getElementsByTagName("script")[0], r = o && o.parentNode || document.head || document.body, c = o || r.lastChild; return null != c ? r.insertBefore(e, c) : r.appendChild(e), this }, o.init = function n(t) { return this.config = t, this.loadScript(t.src, function () { if (o.init === n) throw new Error("Load error!"); o.init(o.config), function () { for (var n = 0; n < r.length; n++) { var t = r[n][0], i = r[n][1]; o[t].apply(o, i) } r = void 0 }() }), this } }();
        // Define config and initialize Lytics tracking tag.
        jstag.init({
          src: '//c.lytics.io/api/tag/45b7a2fa48338cf11ef06bb0386e4843/latest.min.js',
          loadid: true
        });
      },

      /*** **************************************************************************/
      /*** *********************   Private methods - Bombora VI tracker ******* *****/
      /*** **************************************************************************/
      setUpVICookies() {
        let iris_id = getCookie('IRIS_ID');

        if (iris_id) {
          let sp = 'iu-' + iris_id;
          document.cookie = "_iris_fpid=" + btoa(sp) + "; path=/;";
        }
        else {
          let duid = this.getDUId();
          if (duid) {
            let sp = 'id-' + duid;
            document.cookie = "_iris_fpid=" + btoa(sp) + "; path=/;";
          }
        }
      },

      initVIConfig() {
        (function (w, d, t) {
          _ml = w._ml || {};
          _ml.eid = '62439';
          _ml.cid = getCookie('_iris_fpid');
          _ml.fp = ''; /* getCookie('seerid');*/
          //_ml.fp = jstag ? jstag.ckieGet('seerid') : ''; 
          var s, cd, tag;
          s = d.getElementsByTagName(t)[0];
          cd = new Date();
          tag = d.createElement(t);
          tag.async = 1;
          tag.src = 'https://ml314.com/tag.aspx?' + cd.getDate() + cd.getMonth();
          s.parentNode.insertBefore(tag, s);
        })(window, document, 'script');
      },

      /*** **************************************************************************/
      /*** *********************   Private methods - Eloqua Hash Email ******** *****/
      /*** **************************************************************************/
      setEloquaHashEmail() {
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop)
        });
        let value = params.sp_eh;
        if (value) {
          /*document.cookie = "_iris_eloqua_em=" + value + "; path=/;";*/
          this.setDomainCookie('_iris_eloqua_em', value);

        }
      },


      /*** **************************************************************************/
      /*** *********************   Private methods - TD tracker *************** *****/
      /*** **************************************************************************/

      initTDConfig(lob, domain) {

        includeTDScripts();

        const write_apikey = process.env.TD_WRITE_KEY;

        /* Treasure Object contains DB , write key and host details
        Sample DB naming convention webtracking_<lob> */
        this.td = new Treasure({
          database: "webtracking_" + lob,
          writeKey: write_apikey,
          host: "eu01.in.treasuredata.com",
        });

        this.td.setSignedMode();
        this.td.set("$global", "td_global_id", "td_global_id");

        var successCallback = function (td_global_id) { };

        var errorCallback = function (err) {
          console.log(err);
        };

        var afterPageviewCallback = function () {
          this.td.fetchGlobalID(successCallback, errorCallback);
        };

        this.lob = lob;
        this.domain = domain;
        this.setTDCustomData(getCookie('IRIS_ID'));

        this.td.trackPageview("js_pageviews_" + lob + "_" + domain, afterPageviewCallback);
      },

      setTDCustomData(uid) {
        var customdata = {};
        try {
          if (typeof uid !== "undefined") {
            customdata.userid = uid;
          }
        } catch (error) {
          console.log("Error occurred in setTDCustomData ::" + uid)
          return customdata;
        }
        console.log("Setting :: " + customdata.userid);
        this.td.set("js_pageviews_" + this.lob + "_" + this.domain, customdata);
      },
    };

    return trackerObj;
  }

  function getFileLocation() {
    const regex = ".*\/";
    const url = thisScript.src;
    const res = url.match(regex);
    return res[0];
  }

  function getSnowplowUrl() {
    const scriptLocation = getFileLocation();
    const snowplowSrc = `${scriptLocation}sp/v3.21.0/sp.js`;
    return snowplowSrc;
  }

  function includeSnowplowScripts() {
    (function (p, l, o, w, i, n, g) {
      if (!p[i]) {
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
        p.GlobalSnowplowNamespace.push(i);
        p[i] = function () {
          (p[i].q = p[i].q || []).push(arguments);
        };
        p[i].q = p[i].q || [];
        n = l.createElement(o);
        g = l.getElementsByTagName(o)[0];
        n.async = 1;
        n.src = w;
        g.parentNode.insertBefore(n, g);
      }
    })(
      window,
      document,
      "script",
      getSnowplowUrl(),
      "snowplow"
    );
  }

  var aggregatedEvent = {
    minXOffset: 0,
    maxXOffset: 0,
    minYOffset: 0,
    maxYOffset: 0,
    numEvents: 0
  };

  function includeTDScripts() {
    /* this first line is the libarary needed for Treasure Data JS Tag 
      TD library is downloaded from //cdn.treasuredata.com/sdk/3.0/td.min.js and version 3.0 is the latest. this library version should be changed in the future */

    !(function (t, e) {
      if (void 0 === e[t]) {
        (e[t] = function () {
          e[t].clients.push(this),
            (this._init = [Array.prototype.slice.call(arguments)]);
        }),
          (e[t].clients = []);
        for (
          var r = [
            "addRecord",
            "blockEvents",
            "fetchServerCookie",
            "fetchGlobalID",
            "fetchUserSegments",
            "resetUUID",
            "ready",
            "setSignedMode",
            "setAnonymousMode",
            "set",
            "trackEvent",
            "trackPageview",
            "trackClicks",
            "unblockEvents",
          ],
          s = 0;
          s < r.length;
          s++
        ) {
          var c = r[s];
          e[t].prototype[c] = (function (t) {
            return function () {
              return (
                (this["_" + t] = this["_" + t] || []),
                this["_" + t].push(Array.prototype.slice.call(arguments)),
                this
              );
            };
          })(c);
        }
        var n = document.createElement("script");
        (n.type = "text/javascript"),
          (n.async = !0),
          (n.src =
            ("https:" === document.location.protocol ? "https:" : "http:") +
            "//cdn.treasuredata.com/sdk/3.0/td.min.js");
        var o = document.getElementsByTagName("script")[0];
        o.parentNode.insertBefore(n, o);
      }
    })("Treasure", window);
  }

  function isQueryStringValid(qStr) {
    let valid1 = true;
    let valid2 = true;

    if (!(qStr === '')) {
      if (qStr.includes('?') && !qStr.includes('=')) {
        valid1 = false;
      }

      /* if (!(/^\?([\w-]+(=[\w-]+)*(&[\w-]+(=[\w-]+)+)*)*$/.test(qStr))) {
        valid2 = false;;
      }*/
    }
    return (valid1 && valid2);
  }

  function qStrContainsSpParam(cdUrl) {
    if (cdUrl.includes('?_sp=') || cdUrl.includes('&_sp=')) {
      return true;
    }
    else
      return false;
  }

  function isSameDomainLink(urlStr) {
    return (urlStr.indexOf(window.location.hostname) > 0)
  }

  function allowRestrictedLinking(linkElement, crossDomainDests) {
    var lnkUrl = new URL(linkElement.href);
    for (var i = 0; i < crossDomainDests.length; i++) {
      var lnk = crossDomainDests[i];
      var d = lnk.substring(1, lnk.length);
      if (lnk.startsWith('*.') && lnkUrl.hostname.endsWith(d)) {
        return true;
      }
      else if (lnkUrl.hostname == lnk || lnkUrl.hostname == 'www.' + lnk) {
        return true;
      }
    }
    return false;
  }

  function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
  }

  /* returns the SP domain user id */
  function getSnowplowDuid(cookieName) {
    cookieName = cookieName || '_sp_';
    var matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?');
    var match = document.cookie.match(matcher);
    if (match && match[1]) {
      return match[1].split('.')[0];
    } else {
      return false;
    }
  }

  /* Extracts _iris_cdl cookie and decodes the CD hosts */
  function getCrossDomainList() {
    var crossDomainDests = [];
    try {
      var cdl_cookie = getCookie('_iris_cdl');
      var encCDDests = cdl_cookie ? cdl_cookie.split(',') : [];
      if (encCDDests && encCDDests.length > 0) {
        encCDDests.forEach(dest => {
          crossDomainDests.push(atob(dest));
        });
      }
    } catch (e) {
      console.log(e)
    }
    return crossDomainDests;
  }

  /* Fetches the list of whitelisted domains for CDL and creates _iris_cdl cookie */

  function fetchCDWhiteList(domainName) {
    if (!getCookie('_iris_cdl')) {
      let cdl_cfg_url = 'https://static.iris.informa.com/widgets/config/cdl/' + btoa(domainName) + '.json'; 
      try {
        fetch(cdl_cfg_url,).then(response => response.json())
          .then(response => {
            var now = new Date();
            now.setTime(now.getTime() + (24 * 1) * 3600 * 1000);
            document.cookie = "_iris_cdl=" + response + "; path=/; expires=" + now.toUTCString() + ";";
          }).catch(function () {
            console.log('CDL is not configured for ::' + domainName);
          });
      } catch (err) {
        console.log('CDL is not configured for ::' + domainName);
      }
    }
  }

  if (typeof window.IIRISTracker === "undefined") {
    window.IIRISTracker = initTracker();
    window.IIRISTracker.instCount = 0;
    var event = new Event("trackerInitialized");
    window.dispatchEvent(event);
  }
  else {
    if (window.IIRISTracker && !(typeof window.IIRISTracker.instCount === "undefined")) {
      window.IIRISTracker.instCount = window.IIRISTracker.instCount + 1;
    }
  }
})(window);
